import axios from 'axios';
import { authHeader } from '../helpers';

export const DocumentType = {
    GENERIC: 'generic',
    QUOTATION: 'quotation',
    CONTRACT: 'contract',
}

export const documentService = {
    getDocumentUrl,
    uploadDocument,
    deleteDocument
};

function getDocumentUrl(documentID) {
    const headers = {
        headers: authHeader()
    }
    return axios.get(process.env.REACT_APP_BACKEND_URL + 'documents/' + documentID, headers)
        .then(handleResponse)
        .then(url => {
            return url;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function uploadDocument(formData){
    const headers = {
        headers: authHeader()
    }
    return axios.post(process.env.REACT_APP_BACKEND_URL + 'documents/upload/', formData, headers)
        .then(handleResponse)
        .then(result => {
            return result;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function deleteDocument(documentID) {
    const headers = {
        headers: authHeader()
    }
    return axios.delete(process.env.REACT_APP_BACKEND_URL + 'documents/' + documentID, headers)
        .then(handleResponse)
        .then(url => {
            return url;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function handleResponse(response) {
    return response.data;
}