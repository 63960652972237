import { Cookies } from 'react-cookie';
export function authHeader() {
    const cookies = new Cookies();
    const token = cookies.get('token');

    if (token) {
        return {
            'Authorization': 'Bearer ' + token
        };
    } else {
        return {};
    }
}