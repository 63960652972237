import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './assets/css/tailwind.output.css'
import App from './App'
import { SidebarProvider } from './context/SidebarContext'
import ThemedSuspense from './components/ThemedSuspense'
import { Windmill } from '@windmill/react-ui'
import * as serviceWorker from './serviceWorker'
import { store } from './redux/helpers';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import myTheme from './myTheme.js';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { I18nextProvider } from 'react-i18next'

import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

import translationEn from './assets/locales/en.json';
import translationIt from './assets/locales/it.json';
// if (process.env.NODE_ENV !== 'production') {
//   const axe = require('react-axe')
//   axe(React, ReactDOM, 1000)
// }
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: translationEn
      },
      it: {
        translation: translationIt
      }
    },
    lng: "it", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <CookiesProvider>
        <SidebarProvider>
          <Suspense fallback={<ThemedSuspense />}>
            <Windmill light theme={myTheme}>
              <App />
              <ToastContainer />
            </Windmill>
          </Suspense>
        </SidebarProvider>
      </CookiesProvider>
    </I18nextProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
