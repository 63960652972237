import { projectsConstants } from '../constants';
import { projectsService } from '../services';

export const projectsActions = {
    getProjects,
    getProject
};

function getProjects() {
    return dispatch => {
        dispatch(request());
        projectsService.getProjects()
            .then(
                projects => {
                    dispatch(success(projects));
                }
            )
            .catch(
                error => {
                    dispatch(failure(error));
                    // dispatch(alertActions.error(error));
                }
            );
    };

    function request(projects) { return { type: projectsConstants.PROJECTS_REQUEST, projects } }
    function success(projects) { return { type: projectsConstants.PROJECTS_SUCCESS, projects } }
    function failure(error) { return { type: projectsConstants.PROJECTS_FAILURE, error } }
}

function getProject(project) {
    return dispatch => {
        dispatch(request(project));
        projectsService.getProject(project)
            .then(
                project => {
                    dispatch(success(project));
                }
            )
            .catch(
                error => {
                    dispatch(failure(error));
                    // dispatch(alertActions.error(error));
                }
            );
    };

    function request(project) { return { type: projectsConstants.PROJECT_REQUEST, project } }
    function success(project) { return { type: projectsConstants.PROJECT_SUCCESS, project } }
    function failure(error) { return { type: projectsConstants.PROJECT_FAILURE, error } }
}
