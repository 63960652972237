import React, { lazy } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer'
import { PrivateRoute } from './routes/private.route'
import { history } from "./redux/helpers";
import { LoginPage } from "./pages/Login.page";
import { IndexPage } from "./pages/Index.page";
import Cookies from 'universal-cookie';
import { authenticationActions } from './redux/actions';
import { useDispatch } from 'react-redux';
import './styles/globals.css';
import ResetPassword from './pages/ResetPassowrd';

const Layout = lazy(() => import('./containers/Layout'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))

function App() {
  const cookies = new Cookies();
  const dispatch = useDispatch();

  const token = cookies.get('token');
  console.log(token)
  if (token && token.length > 0) {
    console.log('App.js: dispatch validate')
    //check token validity
    dispatch(authenticationActions.validate(token))
  }
  return (
    <>
      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch history={history}>
          {/* <Redirect exact from="/" to="/login" /> */}
          <Route exact path="/" component={IndexPage} />
          <PrivateRoute path="/app" component={Layout} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route exact path="/" component={IndexPage} />
          <Redirect to="/login" />
          {/* Place new routes over this */}
        </Switch>
      </Router>
    </>
  )
}

export default App
