import React, { useState } from 'react'
import { useLocation, useHistory } from "react-router-dom";

import { Label, Input, Button } from '@windmill/react-ui'
import { forgotPassowrdService } from '../redux/services'
import { toast } from 'react-toastify'
import { Trans } from 'react-i18next';
import i18n from 'i18next';

function ResetPassword() {

    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const search = useLocation().search;
    const email = new URLSearchParams(search).get('email');
    const key = new URLSearchParams(search).get('key');
    const history = useHistory()


    function onPasswordChange(event) {
        setPassword(event.target.value)
    };
    function onConfirmPasswordChange(event) {
        setConfirmPassword(event.target.value)
    };
    function sendRequest() {
        const ForgotPasswordPromise = forgotPassowrdService.resetPassword(email, key, password)
        ForgotPasswordPromise.then((result) => {
            history.push('/app/companies')
        })
        toast.promise(
            ForgotPasswordPromise,
            {
                pending: i18n.t("FORGET_PASSWORD_REQUEST"),
                success: i18n.t("SUCCESS_FORGET_PASSWORD_REQUEST"),
                error:   i18n.t("ERROR_FORGET_PASSWORD_REQUEST")
            }
        )
    }
    return (
        <div className="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
            <div className="flex-1 h-full max-w-xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
                <div className="flex flex-col overflow-y-auto md:flex-row">
                    <main className="flex items-center justify-center p-6 sm:p-12 w-full">
                        <div className="w-full">
                            <h1 className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">
                                <Trans i18nKey="FORGOT_PASSWORD" />
                            </h1>

                            <Label>
                                <span><Trans i18nKey="EMAIL" />:</span>
                                <p><b>{email}</b></p>
                            </Label>

                            <Label className="mt-2">
                                <span><Trans i18nKey="PASSWORD" /></span>
                                <Input className="mt-1" placeholder="******" onChange={onPasswordChange} />
                            </Label>

                            <Label>
                                <span><Trans i18nKey="CONFIRM_PASSWORD" /></span>
                                <Input className="mt-1" placeholder="******" onChange={onConfirmPasswordChange} />
                            </Label>

                            {
                                password !== confirmPassword &&
                                <p className="error"><Trans i18nKey="PASSWORD_NOT_MATCH" /></p>
                            }
                            {
                                (password === '' || password !== confirmPassword) &&
                                <Button block className="mt-4" disabled>
                                    <Trans i18nKey="RECOVER_PASSWORD" />
                                </Button>
                            }
                            {
                                !(password === '' || password !== confirmPassword) &&
                                <Button onClick={sendRequest} block className="mt-4">
                                    <Trans i18nKey="RECOVER_PASSWORD" />
                                </Button>
                            }

                        </div>
                    </main>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword
