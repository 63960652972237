import React from 'react'
import { Trans } from 'react-i18next'

function ThemedSuspense() {
  return (
    <div className="w-full h-screen p-6 text-lg font-medium text-gray-600 dark:text-gray-400 dark:bg-gray-900">
      <Trans i18nKey="LOADING" />
    </div>
  )
}

export default ThemedSuspense
