import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { dashboard } from './dashboard.reducer';
import { countries } from './countries.reducer';
import { projects, project } from './projects.reducer';


const rootReducer = combineReducers({
    authentication,
    dashboard,
    projects,
    project,
    countries
});

export default rootReducer;