import { projectsConstants } from '../constants';


export function projects(state = {}, action) {
    switch (action.type) {
        case projectsConstants.PROJECTS_REQUEST:
            return {
                projects: action.projects
            };
        case projectsConstants.PROJECTS_SUCCESS:
            return {
                projects: action.projects
            };
        case projectsConstants.PROJECTS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}

export function project(state = {}, action) {
    switch (action.type) {
        case projectsConstants.PROJECT_REQUEST:
            return {
                project: action.project
            };
        case projectsConstants.PROJECT_SUCCESS:
            return {
                project: action.project
            };
        case projectsConstants.PROJECT_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}