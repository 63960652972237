export const authenticationConstants = {
    LOGIN_REQUEST: 'AUTH_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'AUTH_LOGIN_FAILURE',
    
    VALIDATE_TOKEN_REQUEST: 'AUTH_VALIDATE_TOKEN_REQUEST',
    VALIDATE_TOKEN_SUCCESS: 'AUTH_VALIDATE_TOKEN_SUCCESS',
    VALIDATE_TOKEN_FAILURE: 'AUTH_VALIDATE_TOKEN_FAILURE',

    LOGOUT: 'AUTH_LOGOUT',
};
