import { dashboardConstants } from '../constants';
import { dashboardService } from '../services';

export const dashboardActions = {
    getAnalytics
};

function getAnalytics() {
    return dispatch => {
        dispatch(request());
        dashboardService.getAnalytics()
            .then(
                analytics => {
                    dispatch(success(analytics));
                }
            )
            .catch(
                error => {
                    dispatch(failure(error));
                    // dispatch(alertActions.error(error));
                }
            );
    };

    function request(analytics) { return { type: dashboardConstants.ANALYTICS_REQUEST, analytics } }
    function success(analytics) { return { type: dashboardConstants.ANALYTICS_SUCCESS, analytics } }
    function failure(error) { return { type: dashboardConstants.ANALYTICS_FAILURE, error } }
}
