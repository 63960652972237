import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Cookies  } from 'react-cookie';


const isAuthenticated = () => {
    const cookies = new Cookies();

    const  token  = cookies.get('token');
    try {
        if (token && token.length > 0) {
            return true;
        }
        else {
            return false;
        }
    } catch (error) {
        return false;
    }
}
export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        isAuthenticated()
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )} />
)