import axios from 'axios';


export const forgotPassowrdService = {
    requestUrlWithKey,
    resetPassword
};

function requestUrlWithKey(email) {
    const headers = {}
    const forgotPasswordVariables = {
        email: email
    };
    return axios.post(process.env.REACT_APP_BACKEND_URL + 'forgot-password', forgotPasswordVariables, headers)
        .then(handleResponse)
        .then(response => {
            return response;
        })
        .catch((error) => {
            console.log(error)
            return Promise.reject(error);
        });
}
function resetPassword(email, key, passord) {
    const headers = {}
    const forgotPasswordVariables = {
        email: email,
        forgot_password_key: key,
        new_password: passord
    };
    return axios.patch(process.env.REACT_APP_BACKEND_URL + 'forgot-password', forgotPasswordVariables, headers)
        .then(handleResponse)
        .then(response => {
            return response;
        })
        .catch((error) => {
            console.log(error)
            return Promise.reject(error);
        });
}


function handleResponse(response) {

    return response.data;
}