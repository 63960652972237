import { dashboardConstants } from '../constants';

export function dashboard(state = {}, action) {
    switch (action.type) {
        case dashboardConstants.ANALYTICS_REQUEST:
            return {
                companies: action.companies,
                projectsCount: action.projectsCount
            };
        case dashboardConstants.ANALYTICS_SUCCESS:
            return {
                companies: action.analytics.companies,
                projectsCount: action.analytics.projectsCount
            };
        case dashboardConstants.ANALYTICS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}