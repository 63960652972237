import axios from 'axios';
import { authHeader } from '../helpers';


export const dashboardService = {
    getAnalytics,
};

function getAnalytics() {
    const headers = {
        headers: authHeader()
    }
    return axios.get(process.env.REACT_APP_BACKEND_URL + 'analytics/dashboard', headers)
        .then(handleResponse)
        .then(analytics => {
            return analytics;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}


function handleResponse(response) {

    return response.data;
}