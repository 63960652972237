export default {
  // Button
  button: {
    primary: {
      base: 'text-white bg-blue-600 border border-transparent',
      active:
        'active:bg-blue-600 hover:bg-blue-700 focus:ring focus:ring-blue-300',
      disabled: 'opacity-50 cursor-not-allowed'
    },
    outline: {
      base: 'text-blue-600 border-blue-300 border focus:outline-none',
      active:
        'active:bg-transparent hover:border-blue-500 focus:border-blue-500 active:text-blue-500 focus:ring focus:ring-blue-300',
      disabled: 'opacity-50 cursor-not-allowed bg-grey-300'
    },
    link: {
      base: 'text-blue-600 focus:outline-none border border-transparent',
      active:
        'active:bg-transparent hover:bg-blue-100 focus:ring focus:ring-blue-300',
      disabled: 'opacity-50 cursor-not-allowed'
    }
  },
  // Input
  input: {
    active: 'focus:border-blue-400 focus:ring-blue-300',
    radio:
      'text-blue-600 form-radio focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-offset-0',
    checkbox:
      'text-blue-600 form-checkbox focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-offset-0 rounded'
  },
  textarea: {
    active: 'focus:border-blue-400 focus:ring-blue-300'
  },
  // Select
  select: {
    active: 'focus:border-blue-400 focus:ring-blue-300'
  },
  // Badge
  badge: {
    primary: 'text-blue-700 bg-blue-100'
  }
};
