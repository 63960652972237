import axios from 'axios';
import { authHeader } from '../helpers';

export const ProjectStatus = {
    QUOTATION_REQUEST: 'quotation_request',
    QUOTATION_UPLOADED: 'quotation_uploaded',
    QUOTATION_ACCEPTED: 'quotation_accepted',
    QUOTATION_REJECTED: 'quotation_rejected',
    CONTRACT: 'contract',
    ORDER: 'order',
    ORDER_PROCESSING: 'order_processing',
    ORDER_SHIPPED: 'order_shipped',
    ORDER_COMPLETED: 'order_completed',
}
export const projectsService = {
    createProject,
    getProjects,
    getProject,
    changeProjectStatusTo__quotation_uploaded,
    changeProjectStatusTo__quotation_accepted,
    changeProjectStatusTo__quotation_rejected,
    changeProjectStatusTo__contract,
    changeProjectStatusTo__order,
    changeProjectStatusTo__order_processing,
    changeProjectStatusTo__order_shipped,
    changeProjectStatusTo__order_completed
};

function createProject(project){
    const headers = {
        headers: authHeader()
    }
    console.log(project)
    return axios.post(process.env.REACT_APP_BACKEND_URL + 'projects', project, headers)
        .then(handleResponse)
        .then(project => {
            return project;
        })
        .catch((error) => {
            console.log(error)
            return Promise.reject(error);
        });
}
function getProjects(project_status) {
    let params = {};
    if (project_status) {
        params = {
            project_status: project_status.project_status
        }
    }
    const headers = {
        headers: authHeader(),
        params: params
    }
    console.log(headers)
    return axios.get(process.env.REACT_APP_BACKEND_URL + 'projects', headers)
        .then(handleResponse)
        .then(projects => {
            return projects;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}
function getProject(project) {
    const headers = {
        headers: authHeader()
    }
    return axios.get(process.env.REACT_APP_BACKEND_URL + 'projects/' + project, headers)
        .then(handleResponse)
        .then(projects => {
            return projects;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function changeProjectStatusTo__quotation_uploaded(projectID){
    const newProjectStatus = 'quotation_uploaded';
    return changeProjectStatusRequest(projectID, newProjectStatus)
}
function changeProjectStatusTo__quotation_accepted(projectID){
    const newProjectStatus = 'quotation_accepted';
    return changeProjectStatusRequest(projectID, newProjectStatus)
}
function changeProjectStatusTo__quotation_rejected(projectID){
    const newProjectStatus = 'quotation_rejected';
    return changeProjectStatusRequest(projectID, newProjectStatus)
}
function changeProjectStatusTo__contract(projectID){
    const newProjectStatus = 'contract';
    return changeProjectStatusRequest(projectID, newProjectStatus)
}
function changeProjectStatusTo__order(projectID){
    const newProjectStatus = 'order';
    return changeProjectStatusRequest(projectID, newProjectStatus)
}
function changeProjectStatusTo__order_processing(projectID){
    const newProjectStatus = 'order_processing';
    return changeProjectStatusRequest(projectID, newProjectStatus)
}
function changeProjectStatusTo__order_shipped(projectID){
    const newProjectStatus = 'order_shipped';
    return changeProjectStatusRequest(projectID, newProjectStatus)
}
function changeProjectStatusTo__order_completed(projectID){
    const newProjectStatus = 'order_completed';
    return changeProjectStatusRequest(projectID, newProjectStatus)
}

function changeProjectStatusRequest(projectID, newProjectStatus){
    const headers = {
        headers: authHeader()
    }
    console.log(projectID)
    return axios.patch(process.env.REACT_APP_BACKEND_URL + 'projects/' + projectID + '/' + newProjectStatus, {}, headers)
        .then(handleResponse)
        .then(project => {
            return project;
        })
        .catch((error) => {
            console.log(error)
            return Promise.reject(error);
        });
}

function handleResponse(response) {

    return response.data;
}