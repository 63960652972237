import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'
import Cookies from 'universal-cookie';


class IndexPage extends React.Component {
    render() {
        // const { loggingIn } = this.props;
        const { loggedIn } = this.props;
        const cookies = new Cookies();
        if (loggedIn){
            return <Redirect exact from="/" to="/app/dashboard" />
        }
        const token = cookies.get('token');
        try {
            if (token && token.length > 0) {
                return <div></div>
            }
            else {
                return <Redirect exact from="/" to="/login" />
            }
        } catch (error) {
            return <Redirect exact from="/" to="/login" />
        }
    }
}

function mapStateToProps(state) {
    const { loggedIn, error } = state.authentication;
    return {
        loggedIn, error
    };
}

const connectedIndexPage = connect(mapStateToProps)(IndexPage);
export { connectedIndexPage as IndexPage };