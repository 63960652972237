import { authenticationConstants } from '../constants';
import { authenticationService } from '../services';
import { countriesActions } from '.'
import { toast } from 'react-toastify';

export const authenticationActions = {
    login,
    logout,
    validate
};

function login(email, password) {
    return dispatch => {
        dispatch(request({ email }));
        authenticationService.login(email, password)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(countriesActions.getCountries())
                }
            )
            .catch(
                error => {
                    dispatch(failure(error));
                    // dispatch(alertActions.error(error));
                    toast.error(
                        'Invalid credentials'
                    )
                }
            );
    };

    function request(user) { return { type: authenticationConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: authenticationConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: authenticationConstants.LOGIN_FAILURE, error } }
}

function validate(token) {
    return dispatch => {
        dispatch(request({ token }));
        authenticationService.validateToken(token)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(countriesActions.getCountries())
                }
            )
            .catch(
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(user) { return { type: authenticationConstants.VALIDATE_TOKEN_REQUEST, user } }
    function success(user) { return { type: authenticationConstants.VALIDATE_TOKEN_SUCCESS, user } }
    function failure(error) { return { type: authenticationConstants.VALIDATE_TOKEN_FAILURE, error } }
}

function logout() {
    authenticationService.logout();
    return { type: authenticationConstants.LOGOUT };
}
