import axios from 'axios';
import { Cookies } from 'react-cookie';


export const authenticationService = {
    login,
    logout,
    validateToken
};

function login(email, password) {
    const cookies = new Cookies();

    const loginVariables = {
        "email": email,
        "password": password
    };
    return axios.post(process.env.REACT_APP_BACKEND_URL + 'login', loginVariables, {
    })
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            cookies.set('token', user.accessToken, {
                path: '/',
                maxAge: 3600 || process.env.REACT_APP_TOKEN_EXPIRATION
            });
            

            return user.user;
        })
        .catch((error) => {
            cookies.remove('token')
            return Promise.reject(error);
        });
}

function logout() {
    const cookies = new Cookies();
    // remove user from local storage to log user out
    cookies.remove('token', { path: '/' })
    window.location = '/'
}

function validateToken(token){
    const loginVariables = {
        "token": token
    };
    return axios.post(process.env.REACT_APP_BACKEND_URL + 'login/validate', loginVariables, {
    })
    .then(handleResponse)
    .then(user => {
        return user.user;
    })
    .catch((error) => {
        return Promise.reject(error);
    });
}


function handleResponse(response) {

    if (!response.data.accessToken) {
        if (response.status === 401) {
            logout();
        }

        const error = (response.data && response.data.message) || response.statusText;
        return Promise.reject(error);
    }

    return response.data;
}