import { countriesConstants } from '../constants';
import { countriesStatesService } from '../services/countries_states.service';

export const countriesActions = {
    getCountries
};

function getCountries() {
    return dispatch => {
        dispatch(request());
        countriesStatesService.getCountries()
            .then(
                countries => {
                    dispatch(success(countries));
                }
            )
            .catch(
                error => {
                    dispatch(failure(error));
                    // dispatch(alertActions.error(error));
                }
            );
    };

    function request(countries) { return { type: countriesConstants.COUNTRIES_REQUEST, countries } }
    function success(countries) { return { type: countriesConstants.COUNTRIES_SUCCESS, countries } }
    function failure(error) { return { type: countriesConstants.COUNTRIES_FAILURE, error } }
}
