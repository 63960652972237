import { countriesConstants } from '../constants';


export function countries(state = {}, action) {
    switch (action.type) {
        case countriesConstants.COUNTRIES_REQUEST:
            return {
                countries: []
            };
        case countriesConstants.COUNTRIES_SUCCESS:
            return {
                countries: action.countries
            };
        case countriesConstants.COUNTRIES_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}