import axios from 'axios';
import { authHeader } from '../helpers';


export const countriesStatesService = {
    getCountries,
    getStates
};


function getCountries() {
    const headers = {
        headers: authHeader()
    }
    return axios.get(process.env.REACT_APP_BACKEND_URL + 'countiers-states/countries', headers)
        .then(handleResponse)
        .then(countries => {
            return countries;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function getStates(country) {
    const headers = {
        headers: authHeader()
    }
    return axios.get(process.env.REACT_APP_BACKEND_URL + 'countiers-states/states/' + country, headers)
        .then(handleResponse)
        .then(countries => {
            return countries;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}


function handleResponse(response) {

    return response.data;
}